import {
	checkpaypassword
} from "@/api/order/payment"
import {
	addressList,
	saveAddress,
	setDefault,
	deleteAddress,
	addressInfo
} from "@/api/member/member"
import {
	payment,
	calculate,
	orderCreate
} from "@/api/groupbuy"
import {
	getArea
} from "@/api/address"
import {
	mapGetters
} from "vuex"

export default {
	name: "groupbuy_payment",
	components: {},
	data: () => {
		var checkMobile = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("请输入手机号"))
			} else if (!/^1[3|4|5|6|7|8|9][0-9]{9}$/.test(value)) {
				callback(new Error("手机号格式错误"))
			} else {
				callback()
			}
		}

		return {
			available_list:[
				{
					id:1,
					name:'综合优先'
				},
				{
					id:2,
					name:'低价优先'
				},
				{
					id:3,
					name:'效率优先'
				}
			],
			available_index:0,
			third_party_available:0,
			dialogVisible: false,
			invoiceVisible:false,  //控制发票弹框的隐藏
			memberAddress: {}, //收货地址列表
			invoiceList: [], //发票列表
			siteId:'',
			addressId: 0, //收货地址
			addressForm: {
				id: 0,
				name: "",
				mobile: "",
				telephone: "",
				province_id: "",
				city_id: "",
				district_id: "",
				community_id: "",
				address: "",
				full_address: "",
				is_default: "",
				longitude: "",
				latitude: ""
			},
			invoiceForm: {
				is_invoice:0,
				order_invoice_company:'',
				invoice_type: 1,//纸质or电子
				invoice_title:'',//个人姓名
				taxpayer_number:'',//纳税人识别号
				invoice_content:-1,//发票内容  索引
				invoice_full_address:'',
				is_tax_invoice:1,//是否专用发票
				invoice_email:'',
				invoice_title_type: 1,//个人or企业
				invoice_phone:'',
				invoice_company_address:'',
				invoice_company_number:'',
				invoice_company_bank:'',
				invoice_company_credit:'',
				invoice_content_array: [],
			},
			TaxData: [
				{name: '普通发票',value: 1},
				{name: '专用发票',value: 2},
			],
			paperData: [
				{name: '纸质发票',value: 1},
				{name: '电子发票',value: 2},
			],
			enterpriseData: [
				{name: '个人',value: 1},
				{name: '企业',value: 2},
			],
			pickerValueArray: {},
			cityArr: {},
			districtArr: {},
			addressRules: {
				name: [{
						required: true,
						message: "请输入收货人",
						trigger: "blur"
					},
					{
						min: 1,
						max: 20,
						message: "长度在 1 到 20 个字符",
						trigger: "blur"
					}
				],
				mobile: [{
					required: true,
					validator: checkMobile,
					trigger: "change"
				}],
				province: [{
					required: true,
					message: "请选择省",
					trigger: "change"
				}],
				city: [{
					required: true,
					message: "请选择市",
					trigger: "change"
				}],
				district: [{
					required: true,
					message: "请选择区/县",
					trigger: "change"
				}],
				address: [{
					required: true,
					message: "请输入详细地址",
					trigger: "change"
				}]
			},
			invoiceRules:{
				is_tax_invoice:[
					{required: true, message: '请选择发票类型', trigger: 'change'}
				],
				invoice_type:[
					{required: true, message: '请选择发票形式', trigger: 'change'}
				],
				invoice_title_type:[{
					required: true, message: '请选择发票抬头类型', trigger: 'change'
				}],
				invoice_content:[{
					required: true, message: '请选择发票内容', trigger: 'change'
				}],
				invoice_title:[{
					required: true, message: '请填写姓名', trigger: 'blur' 
				}],
				invoice_phone:[{
					required: true, message: '请填写电话', trigger: 'blur' 
				}],
				taxpayer_number:[{
					required: true, message: '请填写纳税人识别号', trigger: 'blur' 
				}],
				invoice_full_address:[{
					required: true, message: '请填写发票邮寄地址', trigger: 'blur' 
				}],
				invoice_email:[{
					required: true, message: '请填写发票接收邮箱', trigger: 'blur' 
				}],
				order_invoice_company:[{
					required: true, message: '请填写单位名称', trigger: 'blur' 
				}],
				invoice_company_address:[{
					required: true, message: '请填写单位地址', trigger: 'blur' 
				}],
				invoice_company_credit:[{
					required: true, message: '请填写统一社会信用代码', trigger: 'blur' 
				}],
				invoice_company_number:[{
					required: true, message: '请填写账户号码', trigger: 'blur' 
				}],
				invoice_company_bank:[{
					required: true, message: '请填写开户行', trigger: 'blur' 
				}],
			},
			isSend: false,
			orderCreateData: {
				is_balance: 0,
				pay_password: "",
				invoice: {}
			},
			orderPaymentData: {
				goods_money: 0,
				pay_money: 0,
				is_invoice: 0,
				shop_goods_list: {
					site_name: "",
					express_type: [],
					coupon_list: []
				},
				groupbuy_info: {
					name: ""
				},
				member_account: {
					balance: 0,
					is_pay_password: 0
				}
			},
			siteCoupon: {
				site_id: 0,
				data: []
			},
			siteDelivery: {
				site_id: 0,
				data: []
			},
			dialogStore: false,
			promotionInfo: false,
			storeList: {},
			sitePromotion: [],
			isSub: false,
			dialogpay: false,
			password: "",
			fullscreenLoading: true,
			deliveryTime: false,
			timeTip: "选择配送时间",
			time: null,
			addressShow: false,
			storeRadio:0,
		}
	},
	computed: {
		...mapGetters(["groupbuyOrderCreateData", "defaultGoodsImage", "city"])
	},
	mounted() {},
	created() {
		this.getMemberAddress()
		this.getOrderPaymentData()
	},

	filters: {
		/**
		 * 金额格式化输出
		 * @param {Object} money
		 */
		moneyFormat(money) {
			return parseFloat(money).toFixed(2)
		},
		/**
		 * 店铺优惠摘取
		 */
		promotion(data) {
			let promotion = ""
			if (data) {
				Object.keys(data).forEach(key => {
					promotion += data[key].content + "　"
				})
			}
			return promotion
		}
	},
	methods: {
		changeAvailable(e){
			this.orderCreateData.delivery.available_index = e;
			this.orderCreateData.delivery.third_party_available = this.available_list[e].id;
		},
		//获取收货地址
		getMemberAddress() {
			addressList({
					page_size: 0
				})
				.then(res => {
					const {
						code,
						message,
						data
					} = res
					if (data && data.list) {
						let that = this
						this.memberAddress = data.list
						data.list.forEach(function(e) {
							if (e.is_default == 1) {
								that.addressId = e.id
							}
						})
					}
				})
				.catch(err => {
					const {
						code,
						message,
						data
					} = err
					this.$message.error(message)
				})
		},

		//设置会员收货地址
		setMemberAddress(params) {
			this.addressId = params
			setDefault({
					id: params
				})
				.then(res => {
					const {
						code,
						message,
						data
					} = res
					this.orderCalculate()
				})
				.catch(err => {
					const {
						code,
						message,
						data
					} = err
					this.$message.error(message)
				})
		},

		//删除会员收货地址
		deleteMemberAddress(params) {
			deleteAddress({
					id: params
				})
				.then(res => {
					const {
						code,
						message,
						data
					} = res
					if (data) {
						this.$message({
							message: message,
							type: "success"
						})
						this.getMemberAddress()
					} else {
						this.$message({
							message: message,
							type: "warning"
						})
					}
				})
				.catch(err => {
					this.$message.error(err.message)
				})
		},

		//打开添加收货地址弹出层
		addAddressShow() {
			this.dialogVisible = true

			this.addressForm.id = 0
			this.addressForm.name = ""
			this.addressForm.mobile = ""
			this.addressForm.telephone = ""
			this.addressForm.province_id = ""
			this.addressForm.city_id = ""
			this.addressForm.district_id = ""
			this.addressForm.community_id = ""
			this.addressForm.address = ""
			this.addressForm.full_address = ""
			this.addressForm.is_default = ""
			this.addressForm.longitude = ""
			this.addressForm.latitude = ""
			// this.$nextTick(() => {
			// 	this.$refs.form.resetFields();
			// });
			this.cityArr = {}
			this.districtArr = {}
			this.getAddress(0)
		},

		//获取地址
		getAddress(type) {
			let pid = 0
			let that = this
			switch (type) {
				case 0:
					//加载省
					pid = 0
					break
				case 1:
					//加载市
					pid = this.addressForm.province_id
					that.cityArr = {}
					that.districtArr = {}
					this.addressForm.city_id = ""
					this.addressForm.district_id = ""
					break
				case 2:
					//加载区县
					pid = this.addressForm.city_id
					that.districtArr = {}
					this.addressForm.district_id = ""
					break
			}

			getArea({
					pid: pid
				})
				.then(res => {
					const {
						code,
						message,
						data
					} = res
					if (data) {
						switch (type) {
							case 0:
								that.pickerValueArray = data
								break
							case 1:
								//加载市
								that.cityArr = data
								break
							case 2:
								//加载区县
								that.districtArr = data
								break
						}
					}
				})
				.catch(err => {
					const {
						code,
						message,
						data
					} = err
					this.$message.error(message)
				})
		},

		//编辑地址 初始化
		initAddress(type) {
			let pid = 0
			let that = this
			switch (type) {
				case 0:
					//加载省
					pid = 0
					break
				case 1:
					//加载市
					pid = this.addressForm.province_id
					that.cityArr = {}
					that.districtArr = {}
					break
				case 2:
					//加载区县
					pid = this.addressForm.city_id
					that.districtArr = {}
					break
			}

			getArea({
					pid: pid
				})
				.then(res => {
					const {
						code,
						message,
						data
					} = res
					if (data) {
						switch (type) {
							case 0:
								that.pickerValueArray = data
								break
							case 1:
								//加载市
								that.cityArr = data
								break
							case 2:
								//加载区县
								that.districtArr = data
								break
						}
					}
				})
				.catch(err => {
					const {
						code,
						message,
						data
					} = err
					this.$message.error(message)
				})
		},

		//新增/编辑收货地址
		addMemberAddress(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					if (this.isSend) {
						return false
					}

					if (!this.addressForm.id) {
						this.addressForm.full_address = this.$refs.province.selectedLabel + "-" + this.$refs.city.selectedLabel + "-" +
							this.$refs.district.selectedLabel
						let data = {
							name: this.addressForm.name,
							mobile: this.addressForm.mobile,
							telephone: this.addressForm.telephone,
							province_id: this.addressForm.province_id,
							city_id: this.addressForm.city_id,
							district_id: this.addressForm.district_id,
							community_id: 0,
							address: this.addressForm.address,
							full_address: this.addressForm.full_address,
							longitude: this.addressForm.longitude,
							latitude: this.addressForm.latitude,
							is_default: this.addressForm.is_default,
							url: 'add'
						}

						if (!data.province_id || data.province_id <= 0) {
							this.$message({
								message: "请选择省",
								type: "warning"
							})
							return false
						}
						if (!data.city_id || data.city_id <= 0 ) {
							this.$message({
								message: "请选择市",
								type: "warning"
							})
							return false
						}
						if (!data.district_id || data.district_id <= 0) {
							this.$message({
								message: "请选择区/县",
								type: "warning"
							})
							return false
						}
						this.isSend = true

						saveAddress(data)
							.then(res => {
								const {
									code,
									message,
									data
								} = res
								if (data) {
									this.setMemberAddress(data)

									this.$message({
										message: message,
										type: "success"
									})
									this.dialogVisible = false
									this.getMemberAddress()
									this.getOrderPaymentData()
								} else {
									this.$message({
										message: message,
										type: "warning"
									})
								}
								this.isSend = false
							})
							.catch(err => {
								const {
									code,
									message,
									data
								} = err
								this.$message.error(message)
							})
					} else {
						this.addressForm.full_address = this.$refs.province.selectedLabel + "-" + this.$refs.city.selectedLabel + "-" +
							this.$refs.district.selectedLabel
						let data = this.addressForm
						if (!data.province_id) {
							this.$message({
								message: "请选择省",
								type: "warning"
							})
							return false
						}
						if (!data.city_id) {
							this.$message({
								message: "请选择市",
								type: "warning"
							})
							return false
						}
						if (!data.district_id) {
							this.$message({
								message: "请选择区/县",
								type: "warning"
							})
							return false
						}
						this.isSend = true
						this.setMemberAddress(data.id);
						data.url = "edit";
						saveAddress(data)
							.then(res => {
								const {
									code,
									message,
									data
								} = res
								if (data) {
									this.$message({
										message: message,
										type: "success"
									})
									this.dialogVisible = false
									this.getMemberAddress()
									this.getOrderPaymentData()
								} else {
									this.$message({
										message: message,
										type: "warning"
									})
								}
								this.isSend = false
							})
							.catch(err => {
								const {
									code,
									message,
									data
								} = err
								this.$message.error(message)
							})
					}
				} else {
					return false
				}
			})
		},
		// 新增发票信息
		addmemberInvoice(invoiceForm) {
			var phonereg = /^1[3-9]\d{9}$/;
			var emailReg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
			//验证表单
			if(this.invoiceForm.is_tax_invoice == 1 ){
				//普票
				if(!this.invoiceForm.invoice_phone){
					this.$message.error({
						message: '电话不能为空',
						type: "warning"
					})
					return
				}
				if(!phonereg.test(this.invoiceForm.invoice_phone)){
					this.$message.error({
						message: '电话格式有误',
						type: "warning"
					})
					return
				}
				if(this.invoiceForm.invoice_title_type == 1){
					//个人
					if(!this.invoiceForm.invoice_title){
						this.$message.error({
							message: '姓名不能为空',
							type: "warning"
						})
						return
					}
					
				}else{
					//企业
					if(!this.invoiceForm.order_invoice_company){
						this.$message.error({
							message: '单位名称不能为空',
							type: "warning"
						})
						return
					}
					if(!this.invoiceForm.taxpayer_number){
						this.$message.error({
							message: '纳税人识别号不能为空',
							type: "warning"
						})
						return
					}
				}
			}else{
				//专票
				if(!this.invoiceForm.invoice_phone){
					this.$message.error({
						message: '电话不能为空',
						type: "warning"
					})
					return
				}
				if(!phonereg.test(this.invoiceForm.invoice_phone)){
					this.$message.error({
						message: '电话格式有误',
						type: "warning"
					})
					return
				}
				if(!this.invoiceForm.order_invoice_company){
					this.$message.error({
						message: '单位名称不能为空',
						type: "warning"
					})
					return
				}
				if(!this.invoiceForm.taxpayer_number){
					this.$message.error({
						message: '纳税人识别号不能为空',
						type: "warning"
					})
					return
				}
				if(!this.invoiceForm.invoice_company_address){
					this.$message.error({
						message: '单位地址不能为空',
						type: "warning"
					})
					return
				}
				if(!this.invoiceForm.invoice_company_credit){
					this.$message.error({
						message: '统一社会信用代码不能为空',
						type: "warning"
					})
					return
				}
				if(!this.invoiceForm.invoice_company_number){
					this.$message.error({
						message: '账户号码不能为空',
						type: "warning"
					})
					return
				}
				if(!this.invoiceForm.invoice_company_bank){
					this.$message.error({
						message: '开户行不能为空',
						type: "warning"
					})
					return
				}
			}
			if(this.invoiceForm.invoice_type == 1){
				//纸质
				if(!this.invoiceForm.invoice_full_address){
					this.$message.error({
						message: '发票邮寄地址不能为空',
						type: "warning"
					})
					return
				}
			}else{
				//电子
				if(!this.invoiceForm.invoice_email){
					this.$message.error({
						message: '邮箱不能为空',
						type: "warning"
					})
					return
				}
				if(!emailReg.test(this.invoiceForm.invoice_email)){
					this.$message.error({
						message: '邮箱格式有误',
						type: "warning"
					})
					return
				}
			}
			if(this.invoiceForm.invoice_content == -1 ){
				this.$message.error({
					message: '发票内容不能为空',
					type: "warning"
				})
				return
			}
			var store_id = this.orderPaymentData.shop_goods_list.store_id;
			this.invoiceList[store_id].is_invoice = 1
			this.invoiceList[store_id].is_tax_invoice = this.invoiceForm.is_tax_invoice
			this.invoiceList[store_id].invoice_type = this.invoiceForm.invoice_type
			this.invoiceList[store_id].invoice_title_type = this.invoiceForm.invoice_title_type
			this.invoiceList[store_id].invoice_content_array = this.invoiceForm.invoice_content_array
			this.invoiceList[store_id].invoice_content = this.invoiceForm.invoice_content
			this.invoiceList[store_id].invoice_title = this.invoiceForm.invoice_title
			this.invoiceList[store_id].invoice_phone = this.invoiceForm.invoice_phone
			this.invoiceList[store_id].invoice_email = this.invoiceForm.invoice_email
			this.invoiceList[store_id].invoice_full_address = this.invoiceForm.invoice_full_address
			this.invoiceList[store_id].taxpayer_number = this.invoiceForm.taxpayer_number
			this.invoiceList[store_id].order_invoice_company = this.invoiceForm.order_invoice_company
			this.invoiceList[store_id].invoice_company_address = this.invoiceForm.invoice_company_address
			this.invoiceList[store_id].invoice_company_credit = this.invoiceForm.invoice_company_credit
			this.invoiceList[store_id].invoice_company_number = this.invoiceForm.invoice_company_number
			this.invoiceList[store_id].invoice_company_bank = this.invoiceForm.invoice_company_bank
			
			this.orderPaymentData.shop_goods_list.invoice_type = this.invoiceForm.invoice_type
			this.orderPaymentData.shop_goods_list.invoice_title_type = this.invoiceForm.invoice_title_type
			this.orderPaymentData.shop_goods_list.invoice_content = this.invoiceForm.invoice_content
			this.invoiceVisible = false
		},
		// 编辑发票信息
		editInvoice(type) {
			this.invoiceVisible = true
			var store_id = this.orderPaymentData.shop_goods_list.store_id;
			for(let id in this.invoiceList) {
				if(store_id == id) {
					this.invoiceList[id].is_invoice = type;
					// that.orderPaymentData.shop_goods_list[id].invoice_type = that.invoiceList.id.invoice_type
					this.invoiceForm.invoice_type = this.invoiceList[id].invoice_type
					this.invoiceForm.invoice_title_type = this.invoiceList[id].invoice_title_type
					this.invoiceForm.invoice_title = this.invoiceList[id].invoice_title
					this.invoiceForm.invoice_content = this.invoiceList[id].invoice_content
					this.invoiceForm.invoice_content_array = this.invoiceList[id].invoice_content_array
					this.invoiceForm.invoice_email = this.invoiceList[id].invoice_email
					this.invoiceForm.taxpayer_number = this.invoiceList[id].taxpayer_number
					this.invoiceForm.invoice_full_address = this.invoiceList[id].invoice_full_address
					this.invoiceForm.is_tax_invoice = this.invoiceList[id].is_tax_invoice
					this.invoiceForm.invoice_phone = this.invoiceList[id].invoice_phone
					this.invoiceForm.order_invoice_company = this.invoiceList[id].order_invoice_company
					this.invoiceForm.invoice_company_address = this.invoiceList[id].invoice_company_address
					this.invoiceForm.invoice_company_credit = this.invoiceList[id].invoice_company_credit
					this.invoiceForm.invoice_company_number = this.invoiceList[id].invoice_company_number
					this.invoiceForm.invoice_company_bank = this.invoiceList[id].invoice_company_bank
				}
			}
		},
		//编辑收货地址
		editAddress(id) {
			addressInfo({
					id: id
				})
				.then(res => {
					const {
						code,
						message,
						data
					} = res
					this.addressForm = {
						id: data.id,
						name: data.name,
						mobile: data.mobile,
						telephone: data.telephone,
						province_id: data.province_id,
						city_id: "",
						district_id: "",
						community_id: "",
						address: data.address,
						full_address: data.full_address,
						is_default: data.is_default,
						longitude: data.longitude,
						latitude: data.latitude
					}
					this.initAddress(0)
					this.initAddress(1)
					this.addressForm.city_id = data.city_id
					this.initAddress(2)
					this.addressForm.district_id = data.district_id

					this.dialogVisible = true
				})
				.catch(err => {
					const {
						code,
						message,
						data
					} = err
					this.$message.error(message)
				})
		},

		/**
		 * 获取订单初始化数据
		 */
		getOrderPaymentData() {
			this.orderCreateData = this.groupbuyOrderCreateData

			if (!this.orderCreateData) {
				this.$message({
					message: "未获取到创建订单所需数据！", //提示的信息
					type: "warning",
					offset: 225,
					duration: 3000,
					onClose: () => {
						this.$router.go(-1)
						return false
					}
				})
				return
			}
			this.orderCreateData.web_city = this.city ? this.city.id : 0
			
			payment(this.orderCreateData)
				.then(res => {
					const {
						code,
						message,
						data
					} = res
					if (code >= 0) {
						this.orderPaymentData = res.data
						var storeId = this.orderPaymentData.shop_goods_list.store_id;
						let inshopList = JSON.parse(JSON.stringify(data.shop_goods_list));
						let obj = {}
						Object.values(inshopList).forEach((item,index)=>{
							obj[storeId] = {}
							this.invoiceList = obj
						});
						if (!Array.isArray(this.orderPaymentData.shop_goods_list.invoice_config)) {
							this.invoiceList[storeId].is_invoice = this.orderPaymentData.shop_goods_list.is_invoice ? 1 : 0;
							if (this.orderPaymentData.shop_goods_list.invoice && this.orderPaymentData.shop_goods_list.invoice.invoice_content_array) {
								this.invoiceList[storeId].invoice_content_array = this.orderPaymentData.shop_goods_list.invoice.invoice_content_array
							} else {
								this.invoiceList[storeId].invoice_content_array = [];
							}
						}
						Object.values(this.invoiceList).forEach((item,index)=>{
							item.is_tax_invoice = this.invoiceForm.is_tax_invoice
							item.invoice_type = this.invoiceForm.invoice_type
							item.invoice_title_type = this.invoiceForm.invoice_title_type
							item.invoice_content = this.invoiceForm.invoice_content
							item.order_invoice_company = this.invoiceForm.order_invoice_company
							item.taxpayer_number = this.invoiceForm.taxpayer_number
							item.invoice_title = this.invoiceForm.invoice_title
							item.invoice_phone = this.invoiceForm.invoice_phone
							item.invoice_full_address = this.invoiceForm.invoice_full_address
							item.invoice_email = this.invoiceForm.invoice_email
							item.invoice_company_address = this.invoiceForm.invoice_company_address
							item.invoice_company_credit = this.invoiceForm.invoice_company_credit
							item.invoice_company_number = this.invoiceForm.invoice_company_number
							item.invoice_company_bank = this.invoiceForm.invoice_company_bank
						});
						this.handlePaymentData()
					} else {
						this.$message({
							message: "未获取到创建订单所需数据！", //提示的信息
							type: "warning",
							offset: 225,
							duration: 3000,
							onClose: () => {
								this.$router.go(-1)
								return false
							}
						})
						return
					}
				})
				.catch(err => {
					const {
						code,
						message,
						data
					} = err
					this.$message.error(message)
				})
		},
		/**
		 * 处理结算订单数据
		 */
		handlePaymentData() {
			this.orderCreateData.delivery = {}
			this.orderCreateData.coupon = {}
			this.orderCreateData.is_balance = 0
			this.orderCreateData.pay_password = ""

			var data = this.orderPaymentData
			
			let h = new Date().getHours().toString()
			let m = new Date().getMinutes().toString()
			if (h.length == 1) {
				h = "0" + h
			}
			if (m.length == 1) {
				m = "0" + m
			}
			let nowTime = h + ":" + m
			
			if (data.shop_goods_list.local_config) {
				if (data.shop_goods_list.local_config.info && data.shop_goods_list.local_config.info.time_is_open == 1) {
					this.orderCreateData.delivery.showTimeBar = true
					this.orderCreateData.delivery.buyer_ask_delivery_time = nowTime
				} else {
					this.orderCreateData.delivery.showTimeBar = false
				}
			}
			
			if (data.shop_goods_list.express_type != undefined && data.shop_goods_list.express_type[0] != undefined) {
				var express_type = data.shop_goods_list.express_type
				this.orderCreateData.delivery.delivery_type = express_type[0].name
				this.orderCreateData.delivery.delivery_type_name = express_type[0].title
				this.orderCreateData.delivery.store_id = 0
				// 如果默认配送方式是门店配送
				if (express_type[0].name == "store") {
					if (express_type[0].store_list[0] != undefined) {
						this.orderCreateData.delivery.store_id = express_type[0].store_list[0].store_id
					}
				}
			}

			if (data.shop_goods_list.coupon_list != undefined && data.shop_goods_list.coupon_list[0] != undefined) {
				var coupon_list = data.shop_goods_list.coupon_list
				this.orderCreateData.coupon.coupon_id = coupon_list[0].coupon_id
				this.orderCreateData.coupon.coupon_money = coupon_list[0].money
			}

			if (this.orderPaymentData.is_virtual) {
				this.orderCreateData.member_address = {
					mobile: ""
				}
			}
			this.orderCreateData.delivery.available_index = 0;
			this.orderCreateData.delivery.third_party_available = 1
			
			//起送价格的差额
			if(this.orderPaymentData && this.orderPaymentData.shop_goods_list && this.orderPaymentData.shop_goods_list.local_config.info){
				let cha_money = Number(this.orderPaymentData.shop_goods_list.local_config.info.start_money) - Number(this.orderPaymentData.shop_goods_list.goods_money)
				if(cha_money > 0){
					this.orderPaymentData.shop_goods_list.difference_money = cha_money;
				}
			}

			Object.assign(this.orderPaymentData, this.orderCreateData)
			this.orderCalculate()
		},

		/**
		 * 订单计算
		 */
		orderCalculate() {
			this.fullscreenLoading = true
			let siteId = this.orderPaymentData.shop_goods_list.store_id
			
			var deliveryObj = {}
			deliveryObj[siteId] = this.orderCreateData.delivery
			var couponObj = {}
			couponObj[siteId] = this.orderCreateData.coupon
			var messageObj = {}
			messageObj[siteId] = this.orderCreateData.buyer_message
			var data = this.$util.deepClone(this.orderCreateData)
			data.delivery = JSON.stringify(deliveryObj)
			data.coupon = JSON.stringify(couponObj)
			data.buyer_message = JSON.stringify(messageObj)
			data.member_address = JSON.stringify(data.member_address)
			calculate(data)
				.then(res => {
					const {
						code,
						message,
						data
					} = res
					if (code >= 0) {
						this.orderPaymentData.delivery_money = res.data.delivery_money
						this.orderPaymentData.coupon_money = res.data.coupon_money
						this.orderPaymentData.invoice_money = res.data.invoice_money
						this.orderPaymentData.promotion_money = res.data.promotion_money
						this.orderPaymentData.order_money = res.data.order_money
						this.orderPaymentData.balance_money = res.data.balance_money
						this.orderPaymentData.pay_money = res.data.pay_money
						this.orderPaymentData.goods_money = res.data.goods_money
						Object.assign(this.orderPaymentData.shop_goods_list.delivery, res.data.shop_goods_list.delivery);
					} else {
						this.$message({
							message: message, //提示的信息
							type: "warning",
							offset: 225,
							duration: 3000,
							onClose: () => {
								this.$router.go(-1)
								return false
							}
						})
						return
					}
					this.fullscreenLoading = false
				})
				.catch(err => {
					const {
						code,
						message,
						data
					} = err
					this.$message.error(message)
					this.fullscreenLoading = false
				})
		},

		/**
		 * 选择配送方式
		 */
		selectDeliveryType(data) {
			this.orderCreateData.delivery.delivery_type = data.name
			this.orderCreateData.delivery.delivery_type_name = data.title
			// 如果是门店配送
			if (data.name == "store") {
				data.store_list.forEach(function(e, i) {
					data.store_list[i]["store_address"] = e.full_address + e.address
				})

				if (data.store_list[0] != undefined) {
					this.orderCreateData.delivery.store_id = data.store_list[0].store_id
				}
				this.dialogStore = true
				this.storeList = data.store_list
			} else if (data.name == "local") {
				this.deliveryTime = true
			}
			Object.assign(this.orderPaymentData, this.orderCreateData)
			this.orderCalculate()
			this.$forceUpdate()
		},

		/**
		 * 选择自提点
		 * @param {Object} item
		 */
		selectStore(item) {
			return;
			if (!item) return;
			let store_id = item.store_id
			this.dialogStore = false
			this.orderCreateData.delivery[this.siteDelivery.site_id].store_id = store_id
			this.orderCreateData.delivery[this.siteDelivery.site_id].store_name = item.store_name
			Object.assign(this.orderPaymentData, this.orderCreateData)
			this.storeRadio = item
			this.orderCalculate()
			this.$forceUpdate()
		},

		/**
		 * 显示店铺优惠信息
		 * @param {Object} data
		 */
		openSitePromotion(data) {
			this.sitePromotion = data
			if (this.promotionInfo) {
				this.promotionInfo = false
			} else {
				this.promotionInfo = true
			}
		},

		/**
		 * 是否使用余额
		 */
		useBalance(type) {
			if (type ==0) this.orderCreateData.is_balance = 0
			else if(type ==1) this.orderCreateData.is_balance = 1
			this.orderCalculate()
			this.$forceUpdate()
		},
		/**
		 * 是否开票 is_invoice
		 */
		isInvoice(type) {
			if (type == 0){
				this.orderPaymentData.shop_goods_list.is_invoice = 0;
				this.orderPaymentData.shop_goods_list.invoice_title_type = ''
				this.invoiceForm.is_invoice = 0;
				this.invoiceForm.invoice_type = '';
				this.invoiceForm.invoice_title_type = ''
				this.invoiceForm.invoice_title = ''
				this.invoiceForm.invoice_content = -1
			}else if (type == 1 ){
				this.orderPaymentData.shop_goods_list.is_invoice = 1
				this.orderPaymentData.shop_goods_list.invoice_title_type = ''
				this.invoiceForm.is_invoice = 1
				var id = this.orderPaymentData.shop_goods_list.store_id;
				this.invoiceForm.invoice_type = ''
				this.invoiceForm.invoice_title_type = ''
				this.invoiceForm.invoice_title = this.invoiceList[id].invoice_title
				this.invoiceForm.invoice_content = this.invoiceList[id].invoice_content
				this.invoiceForm.invoice_content_array = this.invoiceList[id].invoice_content_array
				this.invoiceForm.invoice_email = this.invoiceList[id].invoice_email
				this.invoiceForm.taxpayer_number = this.invoiceList[id].taxpayer_number
				this.invoiceForm.invoice_full_address = this.invoiceList[id].invoice_full_address
				this.invoiceForm.is_tax_invoice = this.invoiceList[id].is_tax_invoice
				this.invoiceForm.invoice_phone = this.invoiceList[id].invoice_phone
				this.invoiceForm.order_invoice_company = this.invoiceList[id].order_invoice_company
				this.invoiceForm.invoice_company_address = this.invoiceList[id].invoice_company_address
				this.invoiceForm.invoice_company_credit = this.invoiceList[id].invoice_company_credit
				this.invoiceForm.invoice_company_number = this.invoiceList[id].invoice_company_number
				this.invoiceForm.invoice_company_bank = this.invoiceList[id].invoice_company_bank
			}
			this.orderCalculate()
			this.$forceUpdate()
		},
		

		orderCreate() {
			if (this.verify()) {
				if (this.isSub) return
				this.isSub = true

				var loading = this.$loading({
					lock: true,
					text: "订单提交中...",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)"
				})

				let siteId = this.orderPaymentData.shop_goods_list.store_id

				var deliveryObj = {}
				deliveryObj[siteId] = this.orderCreateData.delivery

				var messageObj = {}
				messageObj[siteId] = this.orderCreateData.buyer_message

				var data = this.$util.deepClone(this.orderCreateData)
				data.delivery = JSON.stringify(deliveryObj)
				data.buyer_message = JSON.stringify(messageObj)
				data.member_address = JSON.stringify(data.member_address)
				data.invoice = JSON.stringify(this.invoiceList)

				data.forceLogin = true
				orderCreate(data)
					.then(res => {
						const {
							code,
							message,
							data
						} = res
						loading.close()
						if (code >= 0) {
							this.$store.dispatch("order/removeGroupbuyOrderCreateData", "")
							if (this.orderPaymentData.pay_money == 0) {
								this.$router.push({
									path: "/result",
									query: {
										code: data
									}
								})
							} else {
								this.$router.push({
									path: "/pay",
									query: {
										code: data
									}
								})
							}
						} else {
							this.$message({
								message: message,
								type: "warning"
							})
						}
					})
					.catch(err => {
						loading.close()
						this.isSub = false
						const {
							code,
							message,
							data
						} = err
						this.$message.error(message)
					})
			}
		},
		/**
		 * 订单验证
		 */
		verify() {
			if (this.orderPaymentData.is_virtual == 1) {
				if (!this.orderCreateData.member_address.mobile.length) {
					this.$message({
						message: "请输入您的手机号码",
						type: "warning"
					})
					return false
				}
				var reg = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
				if (!reg.test(this.orderCreateData.member_address.mobile)) {
					this.$message({
						message: "请输入正确的手机号码",
						type: "warning"
					})
					return false
				}
			}

			if (this.orderPaymentData.is_virtual == 0) {
				if (!this.orderPaymentData.member_address) {
					this.$message({
						message: "请先选择您的收货地址",
						type: "warning"
					})

					return false
				}
				if (JSON.stringify(this.orderCreateData.delivery) == "{}") {
					this.$util.showToast({
						title: '店铺【' + this.orderPaymentData.shop_goods_list.store_name + '】未设置配送方式,请联系店铺管理员'
					});
					return false;
				}

				if (this.orderCreateData.delivery.delivery_type == "store" && this.orderCreateData.delivery.store_id == 0) {
					this.$message({
						message: "店铺没有可提货的门店,请选择其他配送方式",
						type: "warning"
					})
					return false
				}
			}

			// if (this.orderCreateData.is_balance == 1 && this.orderCreateData.pay_password == "") {
			// 	this.dialogpay = true
			// 	return false
			// }
			return true
		},
		/**
		 * 支付密码输入
		 */
		input() {
			if (this.password.length == 6) {
				var loading = this.$loading({
					lock: true,
					text: "支付中",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)"
				})

				checkpaypassword({
						pay_password: this.password
					})
					.then(res => {
						const {
							code,
							message,
							data
						} = res
						loading.close()
						if (code >= 0) {
							this.orderCreateData.pay_password = this.password
							this.orderCreate()
							this.dialogpay = false
						} else {
							this.$message({
								message: message,
								type: "warning"
							})
						}
					})
					.catch(err => {
						loading.close()
						const {
							code,
							message,
							data
						} = err
						this.$message.error(message)
					})
			}
		},

		textarea() {
			this.$forceUpdate()
		},
		bindTimeChange(time) {
			this.time = time
			this.orderCreateData.delivery.buyer_ask_delivery_time = this.time
		},
		setDeliveryTime() {
			this.deliveryTime = false
			this.orderCreateData.delivery.buyer_ask_delivery_time = this.time
		},
		imageError(index) {
			this.orderPaymentData.shop_goods_list.goods_list[index].sku_image = this.defaultGoodsImage
		},
		setPayPassword(){
			this.$router.pushToTab("/member/security");
		}
	}
}
